<div id="login-2" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
  <div id="login-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
    <div id="login-form" [ngClass]="{'mt-36': !isMobile && !accountDeletionMode}">
      <div class="dropdown-header primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="dropdown-heading font-size-20 p-12">{{!accountDeletionMode ? 'LOGIN TO YOUR ACCOUNT' : 'Login to Delete Your Account'}}</div>
        <button mat-icon-button class="toggle-sidebar-open mr-8" (click)="toggleSidebarOpen()">
          <mat-icon class="secondary-text">close</mat-icon>
        </button>
      </div>
      <form name="form" #loginForm="ngForm" (ngSubmit)="onLoginSubmit()" class="mt-36 text-center">
        <mat-form-field appearance="outline" class="w-95-p">
          <mat-label>Email</mat-label>
          <input class="form-control" matInput name="username" id="email" [(ngModel)]="login.email" #email="ngModel" type="email" required>
          <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="loginForm.controls['email']?.errors['required'] && email.touched" class="red-900-fg">Email is required.</mat-error>
          <mat-error *ngIf="loginForm.controls['email']?.errors['email']">Email is not valid.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" hintLabel="Min 8 characters" class="w-95-p">
          <mat-label>Password</mat-label>
          <input class="form-control" matInput type="password" name="password" [(ngModel)]="login.pass" #pass="ngModel" minlength="8" required>
          <mat-icon matSuffix>vpn_key</mat-icon>
          <mat-error *ngIf="loginForm.controls['pass']?.errors['required'] && pass.touched" class="red-900-fg">Password is required </mat-error>
          <mat-error *ngIf="pass.value?.length < 8 && pass.touched" class="red-900-fg">Password must be min 8 character </mat-error>
        </mat-form-field>
        <div class="remember-forgot-password" [ngStyle]="{'flex-direction' : isMobile ? 'column' : 'row'}" fxLayoutAlign="end center">
          <a class="forgot-password mr-32 text-bold" (click)="toggleSidebarOpen()" [routerLink]="'/auth/forgot-password'">Forgot Password?</a>
        </div>
        <div *ngIf="errorMessage" class="message-box error mb-12">Your email address or password is incorrect, please try again!</div>
        <div class="text-center mt-20">
          <button mat-raised-button class="primary submit-button" aria-label="LOG IN" type="submit">LOGIN</button>
        </div>
      </form>
      <div class="register" fxLayout="column" fxLayoutAlign="center center" *ngIf="!accountDeletionMode">
        <span class="text">Don't have an account?</span>
        <a class="link text-bold" (click)="toggleSidebarOpen()" [routerLink]="'/auth/register'">Create an account</a>
      </div>
    </div>
  </div>
</div>
